.articles {
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
    padding: 20px 20px 16px;
    max-width: 100%;
    transition: all 0.1s ease;

    &:hover {
        .articles__image::before {
            opacity: 0.6;
            visibility: visible;
        }

        .articles__image::after {
          opacity: 1;
          visibility: visible;
      }
    }

    @media (max-width: 1240px) {
        max-width: 100%;
        padding: 16px;
    }

    &__image {
        position: relative;
        min-height: Min(15.625vw, 300px);
        background: linear-gradient(180deg, #00AEF9 0%, #0047FF 100%);
        border-radius: 12px;
        transition: all 0.1s ease;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg, rgba(0, 71, 254, 0.6), rgba(0, 71, 254, 0.6)), linear-gradient(180deg, #731398 0%, #0047FF 100%);
            z-index: 3;
            opacity: 0;
            transition: all .2s ease-in;
            border-radius: 12px;
            visibility: hidden;
        }

        &::after {
            content: '';
            position: absolute;
            background-image: var(--hover-link);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 189px;
            height: 90px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
            transition: all .2s ease-in;
            visibility: hidden;
        }

        img {
            font-size: 0;
            border-radius: 12px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }

    &__info-head {
        display: flex;
    }

    &__info-sort {
        svg {
            stroke: var(--blue-6);
        }
    }

    &__pubtime {
        margin-left: 16px;
        color: var(--blue-6);
    }

    &__info-details {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
    }
}
