.new {
  padding: 130px clamp(20px, 5.215vw, 30px);
  background-color: var(--system-5);

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 20px;
  }

  &__info-top {
    display: grid;
    grid-template-areas:
      "a b c"
      "a b d";
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "a b "
        "c d ";
    }

    @media screen and (max-width:576px) {
      grid-template-columns: 100%;
      grid-template-areas:
      "a"
      "b"
      "c"
      "d";
    }
  }

  &__top-link {
    position: relative;
    padding: 30px 20px;
    width: 100%;
    z-index: 5;
  }

  &__info-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    z-index: 2;
    transition: all 0.3s;

    @media screen and (max-width: 1024px) {

    }
  }

  &__top-item {
    position: relative;
    min-height: Min(32.639vw, 500px);
    width: 100%;
    border-radius: 16px;
    display: flex;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s;

    &:hover {
      .new__info-img {
        transform: scale(1.1);
      }
    }

    .new__bot-details {
      margin-top: auto;
      align-items: center;
      width: 100%;
    }

    &:nth-last-child(-n + 2) {
      min-height: clamp(230px, 12.5vw, 240px);
    }


    &:nth-child(1) {
      grid-area: a;
    }

    &:nth-child(2) {
      grid-area: b;
    }

    &:nth-child(2),
    &:nth-child(1) {
      @media screen and (max-width: 1240px) {
        min-height:  Min(30vw,372px);
      }

      @media screen and (max-width: 1024px) {
        min-height:  clamp(370px, 45.508vw, 466px)
      }
    }

    &:nth-child(3) {
      grid-area: c;
    }

    &:nth-child(4) {
      grid-area: d;
    }
  }

  &__info-bot {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @media screen and (max-width: 1240px) {
      grid-template-columns: 100%;
    }
  }

  &__info-retail {
    display: flex;
    align-items: center;
  }

  &__bot-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    height: 240px;
    padding: 0 60px;
    z-index: 5;
    transition: all 0.3s;
    width: 100%;

    @media screen and (max-width: 576px) {
      height: 230px;
      padding: 0;
      justify-content: center;
      text-align: center;
    }
  }

  &__bot-item {
    position: relative;
    display: flex;
    height: 240px;
    border-radius: 16px;
    order: 1;
    transition: all 0.3s;
    overflow: hidden;

    @media screen and (max-width: 1440px) {
      .new__info-img {
        object-position: right;
      }
    }

    @media screen and (max-width: 1024px) {
      height: 225px;
    }

    &:hover {
      .new__info-img {
        transform: scale(1.1);
      }
    }

    &:last-of-type {
      @media screen and (max-width: 1240px) {
        order: 0;
      }
    }
  }

  &__bot-details {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;

    @media screen and (max-width: 576px) {
      align-items: center;
    }
  }

  &__bot-title {
    max-width: 400px;
    color: var(--system-6);

    @media screen and (max-width: 769px) {
      max-width: 380px;
    }

    @media screen and (max-width: 769px) {
      max-width: 230px;
    }
  }

  &__retail-title {
    color: var(--system-6);
    margin-right: 12px;
  }

  &__bot-icon {
    svg {
      border-radius: 40px;
    }

    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}
