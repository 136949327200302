.pagination {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 14px;

    &__list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        background: var(--system-4);
        border-radius: 5px;
        transition: 0.4s ease-in-out;

        &--active {
            background-color: var(--blue-6);
            color: var(--system-6);
        }

        &:hover,
        &:active {
            background-color: var(--blue-6);
            color: var(--system-6);
        }
    }
}
