.animation-phone {
    top: -12%;
    left: 40px;
    width: 380px;
    height: 640px;
    position: fixed;

    @media (max-width: 1440px) {
      width: 300px;
      height: 506px;
      top: -5%;
    }

    @media (max-width: 1024px) {
      width: 221px;
      height: 374px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }


