footer {
    background-color: var(--system-1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: clamp(40px, 5.208vw, 100px) clamp(20px, 5.215vw, 30px) 28px;
}

.footer {
    &__copy {
        color: var(--system-3);
    }

    &__top {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1240px) {
            display: grid;
            grid-template-columns: 400px 1fr;
            grid-gap: clamp(60px, 7.813vw, 80px);
            grid-template-areas:
            "video info"
            "nav nav";
            justify-content: flex-start;
        }

        @media (max-width: 1024px) {
            grid-template-columns: clamp(315px, 35.156vw, 360px) 1fr;
        }

        @media (max-width: 576px) {
            grid-template-columns: 100%;
            grid-template-areas:
            "video"
            "info"
            "nav";
        }
    }

    &__navigation {
        display: flex;
        grid-gap: clamp(40px, 3.906vw, 75px);

        @media (max-width: 1240px) {
            grid-area: nav;
            grid-gap: Min(13.672vw, 140px)
        }

        @media (max-width: 576px) {
            flex-direction: column;
            grid-gap: 60px;
        }
    }

    &__nav {
        li + li {
            margin-top: 24px;
        }

        li:first-of-type {
            margin-bottom: 30px;
        }
    }

    &__nav-title {
        color: var(--system-2);
    }

    &__nav-link {
        color: var(--system-6);
        transition: all 0.2s ease;

        &:hover {
          color: var(--blue-6);
      }
    }

    &__info {
        @media (max-width: 1240px) {
            grid-area: info;
        }
    }

    &__info-title {
        max-width: 175px;
        color: var(--system-3);
    }

    &__social {
        margin-bottom: 46px;
    }

    &__socials {
        margin-top: 24px;
        display: flex;
        align-items: center;

        li + li {
            margin-left: 20px;
        }
    }

    &__socials-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: #2f2f2f;
        border-radius: 10px;
        transition: all 0.2s ease;


        &:hover {
            background-color: var(--blue-6);
        }

        svg {
            fill: var(--system-6);
            opacity: 0.9;
        }
    }

    &__payments {
        margin-top: 32px;
        max-width: 234px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 24px 40px;

        @media (max-width: 1240px) {
            max-width: 100%;
            flex-wrap: wrap;
        }

    }

    &__payments-item {
        svg {
            opacity: 0.6;
        }
    }

    &__bot {
        margin-top: 69px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 576px) {
            margin-top: 60px;
            flex-direction: column-reverse;
            grid-gap: 20px;
            width: 100%;
        }
    }

    &__policy {
        display: flex;
        align-items: center;
        grid-gap: 60px;

        @media (max-width: 576px) {
            justify-content: space-evenly;
            width: 100%;
        }
    }

    &__policy-link {
        color: var(--system-6);
        transition: all 0.2s ease;

        &:hover {
          color: var(--blue-6);
        }
    }

    &__video {
        width: clamp(325px, 20.833vw, 400px);
        max-height: 192px;

        @media (max-width: 1240px) {
            grid-area: video;
            width: 100%;
        }

        @media (max-width: 768px) {
            max-height: initial;

            img,
            picture {
                width: 100%;
            }
        }
    }

    &__video-controls {
        width: 90px;
        height: 90px;

        svg {
            height: 33px;
            width: 33px;
        }

        @media (max-width: 1440px) {
          width: 73px;
          height: 73px;

          svg {
              height: 29px;
              width: 27px;
          }
        }

        @media (max-width: 1024px) {
          width: 80px;
          height: 80px;

          svg {
              height: 32px;
              width: 30px;
          }
        }

        @media (max-width: 768px) {
          width: 70px;
          height: 70px;

          svg {
              height: 28px;
              width: 26px;
          }
        }
    }
}






