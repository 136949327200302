.calculate-mobile {
  padding: 0;

  .calculate__wrapper {
    padding-bottom: 160px;
  }

  .calculate__block {
    position: relative;
    grid-gap: 0;
  }

  .calculate__box {
    display: flex;
    grid-gap: 12px;
    border-radius: 0;
  }

  .calculate__box--mid {
    background-color: transparent;
    border: none;
  }

  .calculate__box--left {
    position: relative;
    padding: 0;
  }

  .calculate__shipping {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    grid-gap: 14px;
    z-index: 30;
    min-height: 104px;
    position: fixed;
    bottom: 160px;
    background-color: #0047FE;
    border-radius: 0;
    transform: translateX(0);
    transition: all 1.5s;

    &.hide {
      transform: translateX(100vw);
    }
  }

  .calculate__shipping-close {
    position: absolute;
    top: 10px;
    right: 20px;
    height: 34px;
    width: 34px;
    cursor: pointer;

    svg {
      stroke: #fff;
    }
  }

  .timer__count-content {
    grid-gap: 4px;
  }

  .timer__count-item {
    width: 32px;
    height: 32px;
    background-color: #EDF4FF;
  }

  .timer__count-val {
    color: #0047FE;
  }

  .timer__count-separator {
    color: #EDF4FF;
  }

  .calculate__timer-description {
    flex-direction: row-reverse;
    justify-content: left;
    width: 100%;
  }

  .calculate__shipping-text {
    max-width: 100%;
    color: #fff;
  }

  .calculate__shipping-details {
    height: 24px;

    svg {
      fill: #fff;
    }
  }

  .calculate__upload-first {
    grid-gap: 14px;
  }

  .calculate__upload-label {
    width: 84px;
    height: 84px;
    background-color: #fff;

    svg {
      width: 46px;
      height: 46px;
    }
  }


  .calculate__steps-btns {
    grid-gap: 50px;
  }

  .calculate__breadcrumbs {
    padding: 0 20px;
    border-top: none;
    border-bottom: 1px solid #F0F0F0;
  }

  .calculate__steps-inner {
    margin-top: 0;
    padding: 30px 20px;
    order: 1;
  }

  .calculate__steps-head {
    padding: 0 20px;
  }

  .calculate__types-item {
    position: relative;
    display: flex;
  }

  .calculate__types-info {
    position: relative;
    z-index: 2;
    padding: 20px;
  }

  .calculate__types-descr {
    max-width: 230px;
  }

  .calculate__types-image {
    position: absolute;
    z-index: 1;
    right: 0;
  }

  .calculate__choosed-info {
    width: 155px;

    &--size {
      width: 155px;
    }
  }
}

.calculate-mobile__bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 20;
}
