.mobile {
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

    &__menu {
        position: fixed;
        top: 84px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 82px);
        background-color: var(--system-6);
        transform: translateX(100%);
        transition: all .5s;
        visibility: hidden;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;

        &.active {
            transform: translateX(0);
            visibility: visible;
        }
    }

    &__menu-header {
        width: 100%;
    }

    &__header-btn {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        border: 1px solid var(--blue-6);
        border-radius: 8px;
        padding: 7px;
        cursor: pointer;
        transition: all .4s;

        svg {
            stroke: var(--system-1);
        }

        &:hover {
            background-color: var(--blue-6);

            span {
                color: var(--system-6);
            }

            .burger-menu {
              span {
                background-color: #fff;
              }
            }

            svg {
                stroke: var(--system-6);
            }
        }
    }

    &__menu-wrapp {
        padding: 30px 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        grid-gap: 30px;
    }

    &__nav-menu {
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
    }

    &__menu-bot {
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        padding-bottom: 20px;
    }

    &__bot-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header__lang {
            display: block;
        }
    }

    &__socials {
        display: flex;
        grid-gap: 20px;
    }

    &__socials-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: var(--blue-1);
        border-radius: 10px;
        transition: all 0.4s ease-in;

        svg {
            fill: var(--blue-6);
        }

        &:hover {
            background-color: var(--blue-6);
            filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));

            svg {
                fill: var(--system-6);
            }
        }
    }

    &__btns {
        display: flex;
        flex-direction: column;
        grid-gap: 11px;

        .header__btn--sign,
        .header__btn--log,
        .header__btn--exit {
            display: flex;
            padding: 16px;
        }
    }
}



