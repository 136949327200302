.more {
    padding: 260px clamp(20px,5.215vw,30px);
    overflow: hidden;

    @media (max-width: 1024px) {
        padding: 240px clamp(20px,5.215vw,30px);
    }

    @media (max-width: 768px) {
        padding: 200px clamp(20px,5.215vw,30px);
    }

    &.line::before {
        top: initial;
        bottom: -5%;
        transform: scaleY(-1);
    }

    &__news {
        position: relative;
        width: 100%;

        @media (max-width: 1024px) {
            display: grid;
            grid-gap: 40px;
            grid-template-areas:
            "slider slider"
            "left right";
        }
    }

    &__slider {
        max-width: Min(73.438vw, 1410px);
        width: 100%;

        @media (max-width: 1024px) {
            max-width: 100%;
            grid-area: slider;
        }
    }

    &__arrow {
        position: absolute;
        width: 42px;
        height: 42px;
        background: var(--system-4);
        border-radius: 5px;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
        transition: all 0.4s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            stroke: var(--system-1);
            transition: all 0.4s ease;
        }

        &:hover {
            background-color: var(--blue-6);

            svg {
                stroke: var(--system-6);
            }
        }

        @media (max-width: 1024px) {
            top: initial;
            position: relative;
            transform: initial;
        }

        &--next {
            right: 0;

            @media (max-width: 1024px) {
                grid-area: right;
            }
        }

        &--prev {
            left: 0;

            @media (max-width: 1024px) {
                margin-left: auto;
                grid-area: left;
            }
        }
    }
}
