.article {
    &__image {
        max-height: clamp(203px, 33.229vw, 33.229vw);
        width: 100%;

        @media (max-width: 768px) {
            max-height: initial;
        }
    }

    &__img {
        height: 100%;
        width: 100%;
    }

    &__inner {
        width: 100%;
        margin-top: 40px;
        padding: 0 clamp(20px,5.215vw,30px);
    }

    &__wrapper {
        padding-left: calc((100% - 1526px) / 2 );
    }

    &__wrapp {
        margin-top: 80px;
        position: relative;
        display: flex;
        align-items: flex-start;
        grid-gap: clamp(80px, 8.021vw, 154px);

        @media (max-width: 1440px) {
            grid-gap: 60px;
        }

        @media (max-width: 1024px) {
            grid-gap: 0;
            margin-top: 60px;
        }
    }

    &__info {
        max-width: 1100px;

        @media (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
            flex-shrink: 0;
        }
    }

    &__title {
        margin-bottom: 30px;
    }

    &__status {
        display: flex;
        align-items: center;
        grid-gap: 90px;

        @media (max-width: 768px) {
            grid-gap: 55px;
        }
    }

    &__status-head {
        display: flex;
        align-items: center;

        svg {
            margin-right: 24px;
            stroke: var(--blue-6);
        }
    }

    &__categories-name,
    &__pubtime {
        color: var(--blue-6);
    }

    &__content {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        grid-gap: 35px;

        @media (max-width: 1024px) {
            margin-top: 40px;
        }
    }
}
