.btn {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    svg {
        margin-right: 14px;
    }
}
