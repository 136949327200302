.filter {
    margin-top: 30px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 576px) {
            display: none;
        }

        @media (max-width: 576px) {
            padding: 0 20px;
            position: relative;
            z-index: 10;
            border-radius: 8px;
            border: 1px solid var(--system-3);

            .filter__name--active {
                padding: 0;
                color: var(--blue-6);
                background-color: transparent;

                svg {
                    stroke: var(--blue-6);
                }

                &:hover {
                  background-color: transparent;
                }
            }
        }
    }

    &__body {
        position: absolute;
        top: calc(100% + 10px);
        z-index: 5;

        @media (max-width: 576px) {
            visibility: hidden;
            width: 100%;
            opacity: 0;
        }
    }

    &__wrapp {
        width: 100%;

        @media (max-width: 1240px) {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 12px;
        }

        @media (max-width: 576px) {
            grid-gap: 0;
            flex-direction: column;
            border: 1px solid var(--system-3);
            border-radius: 6px;
            background-color: var(--system-6);

            .filter__name--active {
                color: var(--system-1);
                background-color:  var(--system-6);


                svg {
                    stroke: var(--system-1);
                }
            }
        }
    }

    &__name {
        padding-left: 20px;
        height: 52px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .2s ease-in;
        border-radius: 8px 0px 0px 8px;
        background-color:  var(--system-6);

        svg {
            margin-right: 20px;
            stroke: var(--system-1);
        }

        &:hover {
            color: var(--blue-6);
            background-color:  var(--blue-1);

            svg {
                stroke: currentColor;
            }
        }

        &--active {
            background-color: var(--blue-6);
            color: var(--system-6);

            svg {
                stroke: var(--system-6);
            }

            &:hover {
                background-color: var(--blue-6);
                color: var(--system-6);

                svg {
                  stroke: currentColor;
                }

                @media (max-width: 576px) {
                    background-color: var(--blue-1);
                    color: var(--blue-6);
                }
            }
        }

        @media (max-width: 1240px) {
            padding: 12px 20px;
            border-radius: 8px;
            background-color:  var(--blue-1);

            &--active {
                background-color: var(--blue-6);
            }
        }


        @media (max-width: 576px) {
            padding: 0 20px;
            border-radius: 0;
            background-color:  var(--system-6);
        }
    }
}



