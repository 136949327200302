.preview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-gap: 30px 20px;

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  &__card {
    position: relative;
    max-width: 380px;
    width: 100%;
    min-height: 200px;
    border-radius: 9px;
    border: 1px solid transparent;
    transition: all 0.2s;
    z-index: 1;

    @media (max-width: 576px) {
      min-height: 150px;
    }


    &:hover {
      z-index: 5;

      .preview__card-inner {
        height: calc(100% + 52px);
        background-color: #fff;
        z-index: 10;
        overflow: visible;
        border: 1px solid #0047FE;
      }

      .preview__card-select {
        display: block;
      }

      .preview__print-type {
        background-color: #0047FE;
      }

      .preview__btns {
        display: flex;

        @media (max-width: 576px) {
          width: 100%;
          justify-content: space-between;
          grid-gap: 5px;
        }
      }
    }
  }

  &__card-body {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  &__card-inner {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    border: 1px solid transparent;

    @media (max-width: 576px) {
      padding: 14px;
    }
  }

  &__card-select {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #0047FE;
    background-color: #EDF4FF;
    cursor: pointer;
  }

  &__print-type {
    position: absolute;
    padding: 4px;
    top: 0%;
    right: 0;
    background-color: #ADB4B8;
    color: #fff;
    border-radius: 0px 9px 0px 4px;
    transition: all 0.2s;
  }

  &__card-details {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  &__details-image {
    flex-shrink: 0;
    // width: 164px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 27px;

    @media (max-width: 576px) {
      display: none;
    }
  }

  &__details-info {
    display: flex;
    flex-direction: column;
  }

  &__details-title {
    width: 190px;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: pre;
    margin-bottom: 8px;

    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }

  &__details-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
  }

  &__details-item {
    padding: 4.5px 4px;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    grid-gap: 6px;
    border-radius: 4px;

    span {
      color: #696E72;
    }

    svg {
      fill: #696E72;;
    }
  }

  &__order-price {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    margin-bottom: 15px;
  }

  &__btns {
    display: none;
    align-items: center;
    grid-gap: 55px;
  }

  &__btn-delete {
    cursor: pointer;

    span {
      color: #D5061A;
    }

    svg {
      stroke: #D5061A;
      margin-right: 10px;
    }
  }

  &__btn-test {
    cursor: pointer;

    span {
      color: #0047FE;
    }

    svg {
      stroke: #0047FE;
      margin-right: 10px;
    }
  }


}






input[type=file] {
    display: none;
}



.preview-info-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #42b983;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width .22s;
}
