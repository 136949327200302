.burger-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  grid-gap: 4px;
  z-index: 10;
  position: relative;
  transition: all .2s;

  span {
    display: block;
    background-color: var(--system-1);
    width: 18px;
    height: 2px;
    transition: all .2s;
  }
}

.burger-menu.active {
  span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  span:nth-child(2) {
    opacity: 0;
  }

  span:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -4px);
  }
}
