.payment {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    padding: 20px clamp(10px, 1.563vw, 30px);
    background-color: var(--system-6);
    border-radius: 6px;

    @media all and (max-width: 768px) {
      padding: 20px 30px;

    }

    @media all and (max-width: 576px) {
      padding: 16px 12px;
    }

    &__tabs {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
    }

    &__tabs-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        grid-gap: clamp(15px, 1.563vw, 30px);

        @media all and (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
        }

        @media all and (max-width: 576px) {
          grid-template-columns: 1fr;
          grid-gap: 20px;
        }
    }

    &__item {
        height: 78px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        background-color: var(--system-5);
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &.active {
            background-color: var(--blue-6);

            .payment__btn {
                color: var(--system-6);

                svg {
                    stroke: currentColor;
                }
            }
        }

        &:hover {
            background-color: var(--blue-6);

            .payment__btn {
                color: var(--system-6);

                svg {
                    stroke: currentColor;
                }
            }
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        grid-gap: clamp(10px, 1.042vw, 20px);
        pointer-events: none;
        white-space: nowrap;
        transition: all 0.5s ease-in-out;

        svg {
            height: auto;
            width: clamp(20px, 1.979vw, 38px);
            stroke: var(--blue-6);
        }
    }

    &__content-head {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px;
        background: var(--blue-1);
        border-radius: 8px;

        &--red {
            background-color: #FFEDEF;
            // width: 100%;
        }

        @media all and (max-width: 576px) {
          width: 100%;
        }
    }

    &__content-alert {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        color: #0047FE;

        svg {
            fill: #0047FE;
        }

        &--red {
            color: #D5061A;

            svg {
                fill: #D5061A;
            }
        }
    }

    &__cardpay {
        padding: 40px 30px;
        display: flex;
        grid-gap: 60px;

        @media all and (max-width: 768px) {
          flex-direction: column;
          padding: 0;
        }
    }

    &__cardpay-info {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        grid-gap: 24px;
        padding: 20px;
        background-color: var(--blue-1);
    }

    &__cardpay-head {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    &__card-form {
        grid-gap: 30px;
    }

    &__card-labels {
        display: flex;
        align-items: center;
        grid-gap: 16px;
    }

    &__cardpay-price {
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
    }

    &__pay {
        display: flex;
        align-items: center;
        grid-gap: 24px;
    }

    &__wallet {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
        margin: 0 auto;

        @media all and (max-width: 768px) {
          width: 100%;
        }
    }

    &__wallet-head {
      @media all and (max-width: 576px) {
        width: 100%;

        svg {
          transform: rotate(90deg);
        }
      }
    }

    &__wallet-bot {
        display: flex;
        align-items: center;
        grid-gap: 30px;

        @media all and (max-width: 768px) {
          width: 100%;
          flex-direction: column;
        }
    }

    &__wallet-balance {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 50px;

        @media all and (max-width: 576px) {
          flex-direction: column;
          grid-gap: 20px;
        }
    }

    &__cardpay-total--minus {
        color: #D5061A;
    }

    &__wallet-add {
        padding: 8px 10px;
        color: #0047FE;
        border: 1px solid #0047FE;
        border-radius: 8px;

        svg {
            margin-right: 10px;
            stroke: #0047FE;
        }
    }

    &__toggle {
        position: relative;
        display: flex;
    }

    &__toggle-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #FFF;
        opacity: 0;
        transition: all 0.5s ease;

        &--on {
            left: 16px;
            text-align: left;
        }

        &--off {
            right: 16px;
            text-align: right;
            opacity: 1;
        }
    }

    .payment__toggle-checkbox:checked ~ .payment__toggle-btn--on {
        opacity: 1;
    }

    .payment__toggle-checkbox:checked ~ .payment__toggle-btn--off {
        opacity: 0;
    }

    &__toggle-checkbox {
        position: relative;
        width: 60px;
        height: 30px;
        background-color: #D5061A;
        border-radius: 5px;
        -webkit-appearance: none;
        cursor: pointer;
        transition: all 0.5s;

        &:checked {
            background-color: #23C301;
            transition: all 0.5s;

            &::before {
                left: 44px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 2px;
            width: 14px;
            height: 26px;
            background: #F5F5F5;
            border-radius: 3px;
            transition: all 0.5s;
        }
    }

    &__crypto {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 30px;

        @media all and (max-width: 768px) {
          justify-content: center;
        }
    }

    &__crypto-label {
        position: relative;
    }

    &__crypto-radio {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: all 0.4s;

        &:checked ~ .payment__crypto-info {
            background-color: #0047FE;

            span {
                color: #fff;
            }

            svg {
                fill: #fff;
            }
        }

        &:checked ~ .payment__crypto-info .payment__crypto-name::after{
            opacity: 1;
            background-color: #fff;
        }

        &:checked ~ .payment__crypto-info .payment__crypto-name::before {
            border: 1px solid #fff;
            background-color: #0047FE;
        }
    }

    &__crypto-name {
        position: relative;
        display: inline-block;
        padding: 1px 0 1px 32px;
        transition: all 0.4s;

        &::before {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #181818;
            transition: all 0.4s;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            left: 3px;
            top: 6px;
            width: 14px;
            height: 14px;
            background-color: #0047FE;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.4s;
        }
    }

    &__crypto-info {
        position: relative;
        width: 166px;
        background-color: #F5F5F5;
        border-radius: 14px;
        padding: 18px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
            background-color: #CBE0FE;
            transition: all 0.4s;

            .payment__crypto-name::before {
                border: 1px solid #0047FE;
            }

            .payment__crypto-name::after {
                background-color: #CBE0FE;
                opacity: 1;
            }
        }

        svg {
            fill: #0047FE;
        }
    }
}




