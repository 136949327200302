.line {
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        background-image: var(--line);
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 330px;
        z-index: -1;
    }

    &--animate::before {
        animation: animation-line 4s linear infinite;

    }
}

@keyframes animation-line {
    0% {
        height: 330px;
    }

    50% {

        height: calc(330px - 10%);
    }

    100% {
        height: 330px;
    }

}
