.authorization {
  padding: 35px 40px;
  width: 100vw;
  height: 100vh;

  @media (max-width: 1024px) {
    height: 100%;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
  }

  &__back {
    position: absolute;
    display: flex;
    align-items: center;
    height: auto;
    grid-gap: 14px;
    width: 81px;
    background-color: transparent;
    border-radius: initial;
    right: initial;
    top: initial;
    bottom: 0;
    left: 0;
    cursor: pointer;

    svg {
      stroke: #0047FE;
    }

    span {
      color: #0047FE;
    }

    @media (max-width: 768px) {
      top: 0;
      bottom: initial;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 40px;
    width: 100%;
  }

  &__tabs-content {
    align-items: center;
    width: 100%;
  }

  &__tabs-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 14px;
  }

  &__tabs-item {
    max-width: 210px;
    width: 100%;
    background-color: #EDF4FF;
    border-radius: 6px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    &.active {
      background-color: var(--blue-6);

      .authorization__tabs-btn {
        color: var(--system-6);
      }
    }

    &:hover {
      background-color: var(--blue-6);

      .authorization__tabs-btn {
        color: var(--system-6);
      }
    }

    @media (max-width: 576px) {
      display: none;
    }

    &--mobile {

      &.active {
        @media (max-width: 576px) {
          display: flex;
          width: auto;
          background-color: #CBE0FE;
          padding: 10px 18px;

          span {
            color: var(--blue-6);
          }
        }
      }
    }


  }

  &__tabs-btn {
    display: flex;
    align-items: center;
    pointer-events: none;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;

    @media (max-width: 576px) {
      display: none;
    }

    &--mobile {
      display: none;

      @media (max-width: 576px) {
        display: block;
      }
    }
  }

  &__form {
    width: 100%;
    margin: 0 auto;
    text-align: left;

    fieldset {
      grid-gap: 16px;
    }

    &--login {
      max-width: 500px;
    }

    &--sign {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px 42px;
      max-width: 1080px;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        grid-gap: 25px;
      }
    }
  }

  &__profile-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      position: absolute;
      width: 40%;
      opacity: 0;
      transition: all 0.4s;

      &:checked ~ .authorization__profile-checkbox::after{
        opacity: 1;
        background-color: #0047FE;
      }

      &:checked ~ .authorization__profile-checkbox::before {
        background-color: #EDF4FF;
        border: 1px solid #0047FE;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &__profile-checkbox {
    position: relative;
    padding-left: 32px;
    transition: all 0.4s;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      width: 20px;
      height: 20px;
      border: 1px solid #181818;
      border-radius: 2px;
      transition: all 0.4s;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 2.8px;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-color: #0047FE;
      opacity: 0;
      transition: all 0.4s;
    }

    &:hover {
      &::before {
        border: 1px solid #0047FE;
      }
    }
  }

  &__bottom-text {
    position: fixed;
    right: 40px;
    bottom: 35px;

    @media (max-width: 1024px) {
      position: relative;
      width: 100%;
      right: 0;
      bottom: 0;
      text-align: right;
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__confirm {
    position: fixed;
    right: 40px;
    bottom: 35px;
    display: flex;
    align-items: center;
    grid-gap: clamp(20px, 2.604vw, 50px);

    @media (max-width: 1024px) {
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: 50px;
    }

    @media (max-width: 768px) {
      margin-top: 0;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }

    a {
      color: #0047FE;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    grid-gap: 50px;
  }

  &__bottom-text {
    a {
      color: #0047FE;
      text-decoration: underline;
    }
  }

  &__full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    grid-gap: 50px;
  }

  &__full-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__full-btns {
    max-width: 500px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 10px;

    @media (max-width: 768px) {
      margin-top: 25px;
    }

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
    }
  }

  &__full-btn {
    border: 1px solid #98BEFE;
    width: 100%;
    transition: all 0.2s;

    span {
      color: #0047FE;
    }

    svg {
      fill: #0047FE;
    }

    &:first-child {
      grid-column: span 2;
      background-color: #0047FE;
      transition: all 0.2s;

      span {
        color: #fff;
      }

      svg {
        stroke: #fff;
      }

      @media (max-width: 576px) {
        margin-bottom: 32px;
      }

      &:hover {
        background-image: linear-gradient(180deg, #3F7AFE 0%, #0047FE 100%);
        box-shadow: 0px 0px 22px rgba(21, 33, 155, 0.3);

        svg {
          fill: #0047FE;
        }
      }

      &:active {
        background-color: #0028B6;
        box-shadow: 0px 0px 22px rgba(21, 33, 155, 0.3);

        svg {
          fill: #0047FE;
        }
      }
    }

    &:hover {
      background-image: linear-gradient(180deg, #3F7AFE 0%, #0047FE 100%);

      span {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }

    &:active {
      background-color: #0028B6;

      span {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }
}
