.advantage {
  padding: 130px clamp(20px, 5.215vw, 30px);
  background-color: var(--system-5);

  .container {
    max-width: 1600px;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: clamp(20px, 5.729vw, 110px);

    @media all and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-gap: clamp(60px, 10.417vw, 80px);
    }
  }

  &__item {
    display: flex;
    grid-gap: clamp(20px, 2.083vw, 40px);

    @media all and (max-width: 1440px) {
      flex-direction: column;
    }
  }

  &__item-info {
    max-width: 320px;
  }

  &__item-title {
    @media all and (max-width: 1240px) {
      min-height: 56px;
    }

    @media all and (max-width: 768px) {
      min-height: auto;
    }
  }

  &__item-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 23px;
  }

  &__item-descr {
    margin-top: 11px;
  }
}
