.blog {
    padding: clamp(40px, 5.208vw, 120px) clamp(20px, 5.215vw, 30px) 260px;

    &.line::before {
        top: initial;
        bottom: 0;
        transform: scaleY(-1);
    }

    @media (max-width: 1240px) {
        padding-bottom: 240px;
    }

    @media (max-width: 768px) {
        padding-bottom: 200px;
    }

    &__wrapp {
        display: flex;
        grid-gap: clamp(20px, 2.083vw, 40px);
        position: relative;
        justify-content: space-between;

        @media (max-width: 1240px) {
            flex-direction: column;
        }
    }

    &__wrapp-categories {
        flex-shrink: 0;
        max-height: 955px;
        position: sticky;
        top: 20px;
        width: clamp(305px, 19.271vw, 370px);
        border-right: 1px solid var(--system-3);

        @media (max-width: 1240px) {
            position: initial;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            border-right: none;
        }
    }

    &__categories {
        padding: 14px 20px;
    }

    &__wrapp-articles {
        min-height: auto;
        max-width: 1370px;
        width: 100%;
        z-index: 2;
    }

    &__articles {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
        grid-gap: 100px clamp(10px, 1.563vw, 30px);

        @media (max-width: 1240px) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 60px 15px;
        }

        @media (max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            grid-template-columns: 100%;
        }
    }

    &__articles-count {
      color: var(--system-3);
    }
}
