.tutorials {
    background-color: var(--system-5);
    padding: 80px clamp(20px,5.215vw,30px) 0;
    position: relative;
    z-index: 1;

    @media (max-width: 1024px) {
        padding-top: 60px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        grid-gap: 100px;

        @media (max-width: 1024px) {
            flex-direction: column;
            grid-gap: 60px;
            align-items: center;
        }
    }

    &__videos {
        max-width: 430px;
        width: 100%;
        padding-top: 40px;

        @media (max-width: 1024px) {
            flex-direction: column;
            grid-gap: 60px;
            align-items: center;
            padding-top: 0;
            max-width: 695px;
        }
    }

    &__videos-item {
        @media (max-width: 1024px) {
            max-width: 100%;
            grid-gap: 34px;
        }
    }

    &__videos-all {
        margin-bottom: -150px;
        max-width: 743px;
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        padding: 40px;
        background: var(--system-6);
        border-radius: 8px;

        @media (max-width: 1024px) {
            max-width: 100%;
        }

        @media (max-width: 576px) {
            padding: 30px 20px;
            grid-gap: 30px;
        }
    }

    &__videos-icon {
        margin-right: 22px;
    }

    &__videos-list {
      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        grid: gap 16px;
      }
    }

    &__list {
        border-bottom: 1px solid var(--system-4);
        padding: 20px;

        @media (max-width: 576px) {
            padding: 0 0 16px;
        }
    }

    &__video-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-left: 36px;
        cursor: pointer;

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 8px;
        }

        &::before {
            content: '';
            position: absolute;
            background-image: var(--video-preview);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            left: 0;
        }
    }

    &__bottom {
        padding: 260px clamp(20px,5.215vw,30px);
        overflow: hidden;

        @media (max-width: 1024px) {
            padding: 240px clamp(20px,5.215vw,30px);
        }

        @media (max-width: 768px) {
            padding: 200px clamp(20px,5.215vw,30px);
        }

        &.line::before {
            top: initial;
            bottom: -5%;
            transform: scaleY(-1);
        }
    }

    &__block {
        padding-left: calc((100% - 1526px) / 2 );
        grid-gap: 100px;

        @media (max-width: 1024px) {
          grid-gap: 80px;
        }

        @media (max-width: 1024px) {
          grid-gap: 60px;
        }

        @media (max-width: 1024px) {
          grid-gap: 40px;
        }
    }

    &__block-inner {
        display: flex;
        grid-gap: 80px;
        width: 100%;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    &__accordion {
        max-width: 1056px;
        width: 100%;
    }

    &__form {
        max-width: 578px;
    }
}
