.print-box {
    position: relative;
    height: 182px;
    width: 440px;

    @media (max-width: 1440px) {
      width: 360px;
      height: 134px;
    }

    @media (max-width: 1024px) {
      width: 268px;
      height: 100px;
    }

    @media (max-width: 576px) {
      width: 235px;
      height: 88px;
    }
}

.paper_2 {
    z-index: 18;
    transform: translateX(-50%);
    left: 50%;
    width: 256px;
    height: 0;
    top: 10%;
    border-radius: 16px;
    object-fit: cover;
    object-position: bottom;
    animation: paper_2 6s infinite;
    filter: drop-shadow(0px 4px 52px rgba(0, 0, 0, 0.14));
    will-change: transform;

    @media (max-width: 1440px) {
      width: 210px;
    }

    @media (max-width: 1440px) {
      width: 210px;
      animation: paper_2tablet 6s infinite;
    }

    @media (max-width: 1024px) {
      width: 155px;
    }

    @media (max-width: 576px) {
      width: 137px;

      animation: paper_2mob 6s infinite;
    }
}

@keyframes paper_2 {
  0% {
      opacity: 0;
      top: 10%;
  }

  10% {
      opacity: 1;
  }

  90% {
      top: 30%;
      height: 275px;
      opacity: 1;
  }

  100% {
      top: 35%;
      height: 275px;
      opacity: 0;
  }
}

@keyframes paper_2tablet {
  0% {
      opacity: 0;
      top: 10%;
  }

  10% {
      opacity: 1;
  }

  90% {
      top: 30%;
      height: 175px;
      opacity: 1;
  }

  100% {
      top: 35%;
      height: 175px;
      opacity: 0;
  }
}

@keyframes paper_2mob {
  0% {
    opacity: 0;
    top: 10%;
  }

  10% {
      opacity: 1;
  }

  90% {
      top: 30%;
      // height: 310px;
      height: 150px;
      opacity: 1;
  }

  100% {
      top: 35%;
      // height: 310px;
      height: 150px;
      opacity: 0;
  }
}



.paper_1 {
    width: 256px;
    height: 135px;
    filter: drop-shadow(0px 4px 52px rgba(0, 0, 0, 0.14));
    object-fit: cover;
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 16;
    top: -62%;
    bottom: 10%;
    opacity: 0;
    animation: paper_1 6s infinite;
    will-change: transform;

    @media (max-width: 1440px) {
      width: 210px;
    }


    @media (max-width: 1024px) {
      width: 155px;
      height: 75px;
      animation: paper_1tablet 6s infinite;
    }

    @media (max-width: 576px) {
      width: 137px;
    }
}

@keyframes paper_1 {
  0% {
      top: -62%;
  }

  10% {
      opacity: 1;
  }

  100% {
      top: 20%;
      opacity: 1;
  }
}

@keyframes paper_1tablet {
  0% {
      top: -55%;
  }

  10% {
      opacity: 1;
  }

  100% {
      top: 20%;
      opacity: 1;
  }
}

.layer-image {
    position: absolute;
    display: flex;
}

.layer_1 {
    clip-path: inset(0 0 calc(100% - 12px) 0);
    z-index: 15;
}

.layer_2 {
    clip-path: inset(11px 0 19px 0);
    z-index: 20;
    }

.layer_3 {
    clip-path: inset(calc(100% - 20px) 0 0 0);
    z-index: 18;
}
