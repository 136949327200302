.breadcrumbs {
    display: flex;
    align-items: center;
    grid-gap: 56px;
    width: 100%;
    flex-shrink: 0;
    overflow-x: hidden;
    justify-content: flex-start;
    user-select: none;


    &__prev {
        color: var(--system-3);
        position: relative;
        white-space: nowrap;

        &::before {
            content: '';
            position: absolute;
            background-image: var(--breadcrumbs-arrow);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 16px;
            height: 10px;
            right: -36px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__current {
        white-space: nowrap;
    }

    @media (max-width: 1024px) {
        padding-bottom: 15px;
    }
}
