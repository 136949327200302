.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 60px;

    &__center {
        max-width: 605px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        grid-gap: 40px;

        @media all and (max-width: 1024px) {
          max-width: 680px;
        }
    }

    &__headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        grid-gap: 30px;

        // @media (max-width: 1024px) {
        //     text-align: left;
        // }

        // @media (max-width: 768px) {
        //   text-align: center;
        // }

        // @media (max-width: 576px) {
        //     text-align: center;
        // }
    }

    &__between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 576px) {
            justify-content: center;
        }
    }

    &__between-head {
        display: flex;
        align-items: center;

        .block__icon {
            margin-right: 40px;
        }

        @media (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
            grid-gap: 40px;
            width: 100%;

            .block__icon {
                margin: 0;
            }
        }
    }
}
