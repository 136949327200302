header {
    background-color: var(--system-6);
    min-height: 98px;
    box-shadow: 0px 10px 14px rgba(0, 22, 102, 0.05);

    @media (max-width: 768px) {
        min-height: 84px;
    }
}


.header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: clamp(10px, 3.125vw, 60px);
    padding: 24px clamp(20px, 5.215vw, 30px);

    @media (max-width: 1440px) {
        grid-gap: clamp(10px, 2.083vw, 30px);
    }

    @media (max-width: 1300px) {
        grid-gap: 40px clamp(10px, 2.083vw, 30px);
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        "logo btns"
        "nav nav";
    }

    @media (max-width: 768px) {
        grid-gap: initial;
        padding: 22px clamp(10px, 3.906vw, 30px);
    }

  &__logo {
    display: flex;
    width: clamp(126px, 21.745vw, 167px);
    height: clamp(38px, 6.51vw, 50px);


    @media (max-width: 1300px) {
        grid-area: logo;
    }
  }

  &__nav {
    display: flex;
    align-items: center;


    grid-gap: clamp(60px, 4.167vw, 80px);

    @media (max-width: 1440px) {
        grid-gap: clamp(20px, 4.167vw, 60px);
    }


    @media (max-width: 1300px) {
        grid-area: nav;
        width: 100%;
        max-width: 665px;
        justify-content: space-between;
        margin: 0 auto;
    }

    @media (max-width: 768px) {
        display: none;
    }
  }

  &__nav-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: all 0.2s ease;
    position: relative;
    z-index: 0;

    &--shop {
        color: var(--blue-6);
    }

    svg {
      margin-left: 14px;
    }

    &:hover {
        color: var(--blue-6);

        &::before {
            background-color: var(--blue-1);

            @media (max-width: 1620px) {
                left: -20px;
                right: -20px;
            }

            @media (max-width: 1300px) {
                left: -40px;
                right: -40px;
                top: -25px;
                bottom: -25px;
            }

            @media (max-width: 768px) {
                left: -40px;
                right: -40px;
                top: -18px;
                bottom: -18px;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: -40px;
        right: -40px;
        top: -38px;
        bottom: -37px;
        background-color: transparent;
        z-index: -1;
        transition: all 0.4s;
    }

    // Add active page nav color
    &.active {
        color: var(--blue-6);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 16px;

    @media (max-width: 1300px) {
        grid-area: btns;
    }

    @media (max-width: 768px) {
        grid-gap: 10px;
    }
  }

  &__lang {
    margin-right: clamp(30px, 2.813vw, 54px);

    @media (max-width: 1440px) {
        margin-right: clamp(20px, 1.389vw, 30px);
    }

    @media (max-width: 768px) {
        display: none;
    }
  }

  &__btn {
    border: 1px solid var(--blue-6);
    border-radius: 8px;
    white-space: nowrap;
    transition: all .2s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &--basket {
        padding: 10px;
        color: var(--blue-6);
        width: 44px;
        height: 44px;

        svg {
            stroke: var(--system-1);
        }

        &:hover {
            background-color: var(--blue-6);
            color: var(--system-6);

            svg {
                stroke: var(--system-6);
            }
        }

        @media (max-width: 768px) {
            width: 40px;
            height: 40px;
            padding: 8px;
        }
    }

    &--sign {
        padding: 10px 31px;

        &:hover {
            background: var(--gradient);
            color: var(--system-6);
        }

        &:active {
            background: var(--blue-8);
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    &--log {
        padding: 10px 38px;
        background: var(--blue-6);
        color: var(--system-6);

        svg {
            margin-right: 14px;
            stroke: var(--system-6);
        }

        &:hover {
          background-image: linear-gradient(#3F7AFE 0%, #0047FE 100%);
          filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));
        }

        &:active {
            background-color: var(--blue-8);
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    &--exit {
        padding: 10px 29.5px;
        background-color: #fff;
        color: var(--system-6);
        background: var(--blue-6);
        box-shadow: 0px 0px 22px rgba(21, 33, 155, 0.3);

        svg {
            margin-right: 14px;
            stroke: var(--system-6);
        }

        &:hover {
            background-image: linear-gradient(#3F7AFE 0%, #0047FE 100%);
            filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));
        }

        &:active {
            background-color: var(--blue-8);
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    svg {
      fill: transparent;
    }
  }
}
