.head {
    &--bg {
        background-color: var(--system-5);
    }

    &__inner {
        padding: 40px clamp(20px, 5.215vw, 30px) 0;
    }

    &__title {
        margin-top: 60px;
    }

    &__descr {
        margin-top: 40px;
        max-width: 743px;

        @media (max-width: 1024px) {
            margin-top: 26px;
            max-width: 679px;
        }

        @media (max-width: 576px) {
            margin-top: 18px;
            max-width: 679px;
        }
    }
}
