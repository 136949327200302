.calculate {
  padding: 260px clamp(20px, 5.215vw, 30px) 0;
  background-color: var(--system-5);

  &__block {
    @media (max-width: 1340px) {
      grid-gap: 30px;
    }
  }

  &__between {
    @media (max-width: 1340px) {
      flex-direction: column;
      align-items: baseline;
      grid-gap: 40px;
    }
  }

  &__shipping {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    padding: 14px;
    background-color: var(--system-6);
    border-radius: 8px;

    @media (max-width: 576px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 24px;
    }
  }

  &__timer-description {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__shipping-text {
    max-width: 300px;

    @media (max-width: 576px) {
      max-width: 100%;
    }
  }

  &__shipping-details {
    position: relative;
    transition: all 0.2s ease;

    &:hover {
      .calculate__details-hover {
        opacity: 1;
        overflow: visible;
        z-index: 5;
      }
    }
  }

  &__details-hover {
    position: absolute;
    top: 60px;
    left: -295px;
    right: -15px;
    padding: 10px;
    background: var(--system-6);
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    z-index: -1;
    overflow: hidden;
    opacity: 0;
    transition: all 0.1s ease-in;
    pointer-events: none;

    @media (max-width: 576px) {
      left: -245px;
    }
  }

  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(440px,33%) 1fr minmax(300px, 19%);
    align-items: stretch;
    grid-gap: 20px;

    @media (max-width: 1340px) {
      grid-gap: clamp(15px, 1.563vw, 30px);
    }

    @media (max-width: 1240px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
  }

  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px 30px 20px;
    background-color: var(--system-6);
    border-radius: 6px;
    transition: all 0.2s ease;
    border-bottom: 2px solid #fff;

    &:hover {
      border-bottom: 2px solid #0047FE;
      border-radius: 0px 0px 6px 6px;
    }


    @media (max-width: 1440px) {
      padding: 30px 20px 20px;
    }

    @media (max-width: 576px) {
      display: none;
    }

    &--left {
      max-width: 600px;
      width: 100%;
      grid-gap: 30px;

      @media (max-width: 768px) {
        max-width: 100%;
        grid-column: span 2;
        order: 1;
      }
    }

    &--mid {
      max-width: 840px;
      width: 100%;

      @media (max-width: 768px) {
        min-height: 463px;
        max-width: 100%;
        grid-column: span 2;
      }
    }

    &--right {
      max-width: 360px;
      width: 100%;
      grid-gap: 30px;

      @media (max-width: 1240px) {
        max-width: 100%;
        grid-column: span 2;
        order: 1;
      }

      @media (max-width: 576px) {
        padding: 10px 20px 20px;
      }
    }
  }

  &__box-total {
    border: none;
  }

  &__form {
    height: 100%;
    position: relative;
  }

  &__btn {
    margin-top: 10px;
    background-color: var(--blue-6);
    color: var(--system-6);
    transition: all 0.2s ease-in;

    &:hover {
      filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));
    }

    &:active {
      background-color: var(--blue-8);
    }

    svg {
      stroke: var(--system-6);
    }

    @media all and (max-width: 1240px) {
      max-width: 320px;
      width: 100%;
      margin: 0 auto;
    }

    @media (max-width: 576px) {
      max-width: 100%;
    }
  }


  &__steps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__steps-all {
    display: none;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 20;
    // transform: translateX(-100%);
    // transition: all .4s;
    // opacity: 0;

    &.show {
      background-color: #fff;
      // transform: translateX(0%);
      // opacity: 1;
      display: block;
    }
  }

  &__steps-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__steps-btns {
    display: flex;
    align-items: center;
    grid-gap: 50px;
  }

  &__steps-btn {
    cursor: pointer;

    span {
      color: #0047FE;
    }

    &--back {
      svg {
        stroke: #0047FE;
      }
    }

    &--save {
      flex-direction: row-reverse;

      svg {
        margin-right: 0;
        margin-left: 14px;
        stroke: #0047FE;
      }
    }

    &--delete {
      span {
        color: red;
      }

      svg {
        stroke: red;
      }
    }

    &--add {
      background-color: #0047FE;
      width: 226px;
      transition: all 0.2s ease;

      span {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }


  &__steps-pressed {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;


    &.active {
      display: flex;

    }
  }

  &__upload-add {
    position: relative;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));
    }
  }

  &__steps-choosed {
    display: flex;
    flex-direction: column;
    grid-gap: 26px;
  }

  &__choosed-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__choosed-title {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    span {
      color: #0047FE;
    }

    svg {
      fill: #0047FE;
    }
  }

  &__choosed-alert {
    fill: #ADB4B8;
  }

  &__hover-alert {
    position: relative;

    &:hover {
      .calculate__hover-info {
        display: block;
        transform: translateX(-100%);
        z-index: 3;
      }
    }
  }

  &__hover-info {
    display: none;
    position: absolute;
    filter: drop-shadow(0px 4px 29px rgba(0, 0, 0, 0.15));
    background: #FFFFFF;
    border-radius: 12px;
  }

  &__hover {
    &--big {
      width: 500px;
      display: flex;
      flex-wrap: wrap;

      .calculate__hover-items {
        width: 230px;
      }
    }
  }

  &__hover-items {
    width: 309px;
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    padding: 24px 14px;
    border-bottom: 1px solid var(--system-4);

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom: none;
    }
  }

  &__items-head {
    display: flex;
    grid-gap: 18px;
  }

  &__items-icon {
    display: flex;
  }

  &__items-details {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid var(--system-3);
  }


  &__choosed-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 6px 5px;
  }

  &__choosed-label {
    position: relative;
  }

  &__choosed-input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked~.calculate__choosed-info {
      background-color: #0047FE;

      span {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }

  &__choosed-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 176px;
    height: 56px;
    padding: 7px 5px;
    background-color: #EDF4FF;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #CBE0FE;
      transition: all 0.2s;

      .payment__crypto-name::before {
        border: 1px solid #0047FE;
      }

      .payment__crypto-name::after {
        background-color: #CBE0FE;
        opacity: 1;
      }
    }

    svg {
      fill: #0047FE;
    }

    &--size {
      width: 130px;
    }

    &--disabled {
      pointer-events: none;
      background-color: #F0F0F0;

      span {
        color: #696E72;
      }
    }

    &--row {
      flex-direction: row;
      grid-gap: 12px;
      text-align: left;
      padding: 7px 8px 7px 12px;

      svg {
        fill: #fff;
        stroke: #0047FE;
      }
    }
  }

  &__form-btn {
    background-color: #0047FE;

    span {
      color: #fff;
    }

    svg {
      stroke: #fff;
    }
  }

  &__choosed-description {
    display: flex;
    flex-direction: column;
  }

  &__box-head {
    @media all and (max-width: 1240px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-gap: 20px;
    }

    @media all and (max-width: 768px) {
      display: grid;
      grid-template-columns: auto 1fr;
    }

    @media all and (max-width: 576px) {
      display: flex;
    }

  }

  &__box-items {
    @media all and (max-width: 1240px) {
      flex-direction: row;
      order: 1;
      flex-grow: 1;

      span {
        white-space: nowrap;
      }
    }

    @media all and (max-width: 992px) {
      span {
        white-space: initial;
      }
    }

    @media all and (max-width: 768px) {
      grid-gap: 10px;
    }

    @media (max-width: 576px) {
      display: flex;
      grid-gap: 24px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  &__box-item {
    @media all and (max-width: 1240px) {
      width: 100%;
      grid-gap: 0;
    }

    @media all and (max-width: 768px) {
      max-width: 130px;
    }
  }

  &__files {
    @media all and (max-width: 1240px) {
      order: 1;
      max-width: 285px;
      width: 100%;
    }

    @media all and (max-width: 768px) {
      order: 0;
      max-width: 100%;
      grid-column: span 2;
    }

  }

  &__files-head {
    @media all and (max-width: 1240px) {
      max-width: 285px;
    }

    @media all and (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__box-total {
    @media all and (max-width: 1240px) {
      font-size: 36px;
      line-height: 139%;
      padding: 7px;
      order: 0;
      max-width: 144px;
      width: 100%;
      border-top: none;
      background-color: #EDF4FF;
      border-radius: 6px;
      min-height: 83px;
      grid-gap: 0;

      span {
        color: #0047FE;
        white-space: nowrap;
      }
    }

    @media all and (max-width: 768px) {
      min-width: 130px;
    }

    @media all and (max-width: 576px) {
      min-width: auto;
      max-width: 90px;
      min-height: initial;
    }
  }

  &__total {
    @media all and (max-width: 1240px) {
      font-size: 36px;
      line-height: 139%;
      font-weight: 600;
    }

    @media all and (max-width: 576px) {
      font-size: 22px;
      line-height: 115%;
    }
  }

  &__files-head {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    background: #F5F5F5;
    border-radius: 8px;

    &--normal {
      background: #EDF4FF;
    }

    &--over {
      background: #FFEDEF;

      .payment__content-alert {
        span {
          color: #D5061A;
        }

        svg {
          fill: #D5061A;
        }
      }
    }
  }

  &__steps > .calculate__steps-inner  {
    overflow: initial;
    max-height: initial;
  }


  &__steps-inner {
    margin-top: 20px;
    max-height: 550px;
    flex-grow: 2;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  &__types {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &__types-image {
    display: flex;
    align-items: flex-end;
    max-width: 190px;

    img,picture {
      width: 100%;
    }
  }

  &__types-item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    justify-content: space-between;
    grid-gap: 7px;
    background-color: rgba(240, 240, 240, 0.5);
    border-radius: 6px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: #0047FE;

      .calculate__types-name {
        color: #fff;
      }

      .calculate__types-descr {
        color: #fff;
      }
    }

    &:active {
      background-color: #001C93;

      .calculate__types-name {
        color: #fff;
      }

      .calculate__types-descr {
        color: #fff;
      }
    }
  }

  &__types-info {
    display: flex;
    flex-direction: column;
    padding: 21px 0 21px clamp(15px, 1.563vw, 30px);
    grid-gap: 9px;
  }

  &__types-name {
    transition: all 0.1s;
  }

  &__types-descr {
    max-width: 300px;
    width: 100%;
    color: #696E72;
    transition: all 0.2s;

    @media all and (max-width: 1440px) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 40px;
    }
  }

  &__breadcrumbs {
    height: 60px;
    display: flex;
    align-items: center;

    grid-gap: 20px;
    border-top: 1px solid #F0F0F0;
  }

  &__breadcrumbs-item {
    color: #ADB4B8;

    &--active {
      color: #181818;
    }
  }

  &__mobile {
    display: none;

    @media all and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__mobile-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mobile-info {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    padding: 14px;
    background-color: #0047FE;
    border-radius: 8px;
  }

  &__mobile-link {
    background-color: #fff;

    span {
      color: #0047FE;
    }

    svg {
      stroke: #0047FE;
    }
  }

  &__upload {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__upload-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__upload-form {
    height: 100%;
  }

  &__upload-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    grid-gap: 40px;

    @media all and (max-width: 768px) {
      grid-gap: 30px;
    }
  }

  &__upload-files {
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: calc(100%  - 86px);
    bottom: 0;
    left: 0;
    padding: 15px 20px 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    display: none;
    transition: all .2s;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &.active {
      display: block;
    }

    @media (max-width: 1400px) {
      height: calc(100%  - 120px);
    }

    @media (max-width: 576px) {
      background-color: transparent;
      height: calc(100%  - 60px);
    }
  }


  .has-file {
    .calculate__upload-wrapper {
      @media (max-width: 576px) {
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .calculate__upload {
      position: absolute;
      top: -15px;
      right: 0;
      width: fit-content;
      height: fit-content;

      @media (max-width: 576px) {
        position: relative;
        top: 0;
      }
    }

    .has-file__delete {
      width: 120px;
      display: flex;

      span {
        white-space: nowrap;
        color: #D5061A;
      }

      svg {
        stroke: #D5061A;
      }

      @media (max-width: 576px) {
        width: auto;
        height: auto;

        span {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }
    }

    .hiden-text {
      display: initial;
    }


    .calculate__upload-first {
      display: flex;
      height: fit-content;
      align-items: center;
      grid-gap: clamp(30px, 3.646vw, 70px);
      flex-direction: row-reverse;
      margin: 0;

      @media (max-width: 1400px) {
        flex-direction: column;
        grid-gap: 0;
      }

      @media (max-width: 576px) {
        flex-direction: row-reverse;
        grid-gap: 20px;
      }
    }


    .calculate__upload-details {
      display: none;
    }

    .calculate__upload-label {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 14px;
      background-color: #0047FE;
      height: 56px;
      width: 226px;
      border-radius: 8px;

      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }

      span {
        color: #fff;
      }

      @media (max-width: 576px) {
        width: auto;
        height: auto;
        background-color: transparent;
        font-size: 16px;
        line-height: 20px;
        grid-gap: 10px;

        span {
          color: #0047FE;
        }

        svg {
          fill: #0047FE;
        }
      }
    }
  }




  .hiden-text {
    display: none;
  }

  .has-file__delete {
    display: none;
  }

  &__upload-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    background-color: #EDF4FF;
    border-radius: 24px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &:active {
      background-color: #0047FE;

      svg {
        fill: #fff;
      }
    }

    svg {
      transition: all 0.2s ease;
      fill: #0047FE;
    }

    .hiden-text {
      display: none;
    }

    @media all and (max-width: 768px) {
      width: 120px;
      height: 120px;

      svg {
        width: 66px;
        height: 66px;
      }
    }
  }

  &__upload-file {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &__upload-details {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
  }

  &__upload-descr {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
  }
}
