.faq {
  padding: 130px clamp(20px, 5.215vw, 30px) 0;
  position: relative;
  z-index: 1;

  &__accordion {
    margin-bottom: -110px;
    max-width: 900px;
    width: 100%;
    background-color: var(--system-6);
    box-shadow: 0px 16px 30px rgba(0, 22, 102, 0.16);
    border-radius: 16px;
  }

  &__accordion-item {
    background-color: var(--system-6);
    border-bottom: 1px solid var(--system-4);

    &:first-child {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      .faq__accordion-title {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }

    &:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .faq__accordion-title {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .faq__accordion-title._active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &__accordion-title {
    width: 100%;
    padding: 20px;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    transition: all 0.4s ease;

    &:hover {
      background-color: #EDF4FF;
    }

    &._active {
      background-color: #0047FE;
      color: #fff;

      .faq__accordion-title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &::before,
      &::after {
        background-color: #fff;
      }
    }

    &::before,
    &::after {
      content: "";
      width: 18px;
      height: 2px;
      background-color: var(--system-1);
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease 0s;
    }

    &::after {
      transform: rotate(-90deg);
    }

    &._active::after {
      transform: rotate(45deg);
    }

    &._active::before {
      transform: rotate(-45deg);
    }

    @media (max-width: 575px) {
      padding-right: 50px;
      text-align: left;
    }
  }

  &__accordion-text {
    padding: 20px;
  }

  &__email {
    color: var(--blue-6);
  }
}
