body {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  // overflow-x: hidden;
}

html {
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar {
  width: 3px; /* ширина для вертикального скролла */
  // background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #0047FE;
  border-radius: 14px;
}

// *::-webkit-scrollbar-thumb:hover {
//   background-color: #253861;
// }


a {
  display: inline-flex;
  color: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  display: inline-flex;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: initial;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

picture {
  display: inline-flex;
}

fieldset {
    padding: 0;
    outline: none;
    border: none;
}

input {
  width: 100%;
  outline: none;
  border: none;
}

textarea {
    width: 100%;
    outline: none;
    border: none;
}

label {
  display: block;
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  width: 100%;
  max-width: 1545px;
  margin: 0 auto;
  position: relative;
  padding: 0 10px;

  &--fluid {
    max-width: 1920px;
  }
}

svg {
  fill: transparent;
}

.body-scroll {
    position: fixed;
    overflow-y: hidden;
}






