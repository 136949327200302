.about {
  padding: 130px 10px;

  &__wrapp {
    grid-gap: 100px;
  }

  &__partners {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 60px Min(8.115vw, 175px);
  }

  &__partners-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 75px;
    transition: 0.2s all ease-in;
    opacity: 0.6;

    @media all and (max-width: 1024px) {
      width: 160px;
      height: 48px;
    }

    @media all and (max-width: 575px) {
      width: 134px;
      height: 40px;
    }

    img {
      filter: grayscale(100);
    }

    &:hover {
      opacity: 1;

      img {
        filter: grayscale(0);
      }
    }
  }
}
