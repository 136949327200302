.title-1 {
    font-family: var(--font-family);
    font-size: 90px;
    line-height: 107%;
    font-weight: 800;
    letter-spacing: -2px;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 1024px) {
        font-size: 60px;
        line-height: 117%;
    }

    @media (max-width: 768px) {
        max-width: 663px;
        font-size: 50px;
        line-height: 120%;
    }

    @media (max-width: 576px) {
      font-size: 36px;
      line-height: 122%;
    }
}

.title-2 {
    font-family: var(--font-family);
    font-size: 46px;
    line-height: 120%;
    font-weight: 800;
    font-style: normal;
    color: var(--system-1);
    letter-spacing: -1px;

    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 115%;
    }

    @media (max-width: 768px) {
        font-size: 32px;
        line-height: 119%;
    }
}


.title-3 {
    font-family: var(--font-family);
    font-size: 32px;
    line-height: 106%;
    font-weight: 800;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 1024px) {
        font-size: 26px;
        line-height: 119%;
    }
}

.title-4 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 117%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 576px) {
        font-size: 14px;
        line-height: 120%;
    }
}


.title-5 {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 122%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);
}

.sale-text {
    font-family: var(--font-family);
    font-size: 26px;
    line-height: 120%;
    font-weight: 800;
    font-style: italic;
    text-transform: lowercase;
    color: var(--system-1);

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 122%;
    }
}

.text-headline {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 143%;
    }

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 150%;
     }
}

.menu-text {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 125%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);
}

.star-text {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 125%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);
}

.reviews-text {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 125%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);
}

.text {
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 160%;
    font-weight: 400;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 162%;
    }

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 171%;
    }
}

.text-body {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 144%;
    font-weight: 400;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 163%;
    }

    @media (max-width: 768px) {
        font-size: 13px;
        line-height: 169%;
    }
}

.button-1 {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 125%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.button-2 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 128%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);
}

.button-filtr {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 125%;
    font-weight: 500;
    font-style: normal;
    color: var(--system-1);
}

.text-detail {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 123%;
    font-weight: 500;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 125%;
    }
}

.text-decor {
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 120%;
    font-weight: 900;
    font-style: italic;
    color: var(--system-1);
}

.button-footer {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 125%;
    font-weight: 500;
    font-style: normal;
    color: var(--system-1);
}

.text-calc-name {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 125%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 125%;
     }
}

.text-calc-descr {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 143%;
    font-weight: 500;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 167%;
  }
}

.text-calc-other {
    font-family: var(--font-family);
    font-size: 36px;
    line-height: 139%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 576px) {
        font-size: 22px;
        line-height: 115%;
    }
}

.text-calc-btn {
    font-family: var(--font-family);
    font-size: 40px;
    line-height: 120%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);
}

.text-calc-total {
    font-family: var(--font-family);
    font-size: 50px;
    line-height: 100%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);
}

.text-basket {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 125%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);
}

.basket-count {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 125%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);
}

.text-town {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 120%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-1);

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 125%;
    }
}

.basket-copy {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 115%;
    font-weight: 400;
    font-style: normal;
    color: var(--system-1);
}

.basket-choosed {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 120%;
    font-weight: 600;
    font-style: normal;
    color: var(--system-2);
}

.agreements-text {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 171%;
    font-weight: 400;
    font-style: normal;
    color: var(--system-1);
}

.basket-tabs-content {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 125%;
    font-weight: 700;
    font-style: normal;
    color: var(--system-1);
}

.calculate-mobile {
  font-family: var(--font-family);
  font-size: 22px;
  line-height: 127%;
  font-weight: 800;
  font-style: normal;
  color: var(--system-6);
}

.calculate-hover-descr {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  font-style: normal;
  color: var(--system-2);
}

.calculate-hover {
  font-family: var(--font-family);
  font-size: 13px;
  line-height: 138%;
  font-weight: 400;
  font-style: normal;
  color: var(--system-1);
}
