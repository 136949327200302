.animation {
    max-width: 1526px;
    margin: 0 auto;
    width: 100%;
    height: 500px;
    background: var(--gradient);
    border-radius: 20px;
    transform: translateY(50%);

    &__printing {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;

        @media (max-width: 768px) {
          left: 5%;
          transform: initial;
        }

        @media (max-width: 576px) {
          z-index: 10;
          left: 50%;
          transform: translateX(-50%);
        }
    }

    // &__message-list {
    //     position: absolute;
    //     right: -30px;
    //     top: 30px;

    //     @media (max-width: 1024px) {
    //       right: -5px;
    //     }

    //     @media (max-width: 576px) {
    //       left: 50%;
    //       right: initial;
    //       transform: translateX(-50%);
    //     }
    // }

    @media (max-width: 1440px) {
      height: 451px;
    }

    @media (max-width: 1024px) {
      height: 333px;
    }

    @media (max-width: 768px) {
      height: 307px;
    }

    &__phone-img {
      position: absolute;
      width: 310px;
      height: 566px;
      top: 25px;
      left: 35px;

      @media (max-width: 1440px) {
        width: 243px;
        height: 448px;
      }

      @media (max-width: 1024px) {
        height: 315px;
        width: 170px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
}


