@mixin font-face($font-family, $url, $weight, $style) {
  @font-face {
    font-family: "#{$font-family}";
    src: url('../fonts/#{$url}.woff2') format("woff2"),
         url('../fonts/#{$url}.woff') format("woff");
    font-weight: #{$weight};
    font-display: swap;
    font-style: $style;
  }
}

@include font-face("Mulish", "../fonts/Mulish-Regular", 400, normal);
@include font-face("Mulish", "../fonts/Mulish-Medium", 500, normal);
@include font-face("Mulish", "../fonts/Mulish-SemiBold", 600, normal);
@include font-face("Mulish", "../fonts/Mulish-Bold", 700, normal);
@include font-face("Mulish", "../fonts/Mulish-ExtraBold", 800, normal);
@include font-face("Mulish", "../fonts/Mulish-Black", 900, normal);



