.sale {
    padding-top: 130px;
    pointer-events: none;

    &.line:before {
        background-image: var(--line-center);
        background-position: center;
    }

    &__slider {
        background-color: var(--blue-6);
        overflow: hidden;
    }

    &__wrapper {
        -webkit-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
    }

    &__slide {
        max-width: 370px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 769px) {
          justify-content: flex-start;
          max-width: 335px;
        }
    }

    &__slide-item {
        height: 60px;
        display: flex;
        align-items: center;
    }

    &__text {
        color: var(--system-6);
        white-space: nowrap;
    }

    &__cirle{
        margin-left: 60px;
        width: 10px;
        height: 10px;
        background-color: var(--blue-3);
        border-radius: 3px;

        // @media (max-width: 769px) {
        //   margin-left: 75px;
        // }
    }
}
