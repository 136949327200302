.description {
  padding: 620px 120px 130px;

  @media all and (max-width: 1440px) {
    padding-top: 450px;
  }

  @media all and (max-width: 1024px) {
    padding: 360px 30px 130px;
  }

  @media all and (max-width: 768px) {
    padding: 320px 9px 100px;
  }

  &__wrapp {
    grid-gap: clamp(60px, 4.167vw, 80px);
  }

  &__headline {
    text-align: center;
  }

  &__video {
    position: relative;
    max-width: 1526px;
    margin: 0 auto;
    width: 100%;
  }

  &__video-controls {
    width: 140px;
    height: 140px;

    svg {
      height: 56px;
      width: 51px;
    }

    @media all and (max-width: 1440px) {
      width: 100px;
      height: 100px;

      svg {
        height: 40px;
        width: 36px;
      }
    }

    @media all and (max-width: 1024px) {
      width: 75px;
      height: 75px;

      svg {
        height: 30px;
        width: 28px;
      }
    }

    @media all and (max-width: 768px) {
      width: 52px;
      height: 52px;

      svg {
        height: 21px;
        width: 19px;
      }
    }
  }
}
