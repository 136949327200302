.video {
    position: relative;
    cursor: pointer;

    &__image {
        position: relative;

        img {
          border-radius: 14px;
        }
    }

    &__controls {
        transition: all .5s ease;

        &:hover {
            background-color: rgba(0, 71, 254, 0.8);
        }
    }

    &__controls {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(24, 24, 24, 0.5);
    }

}
