.animation-message {
  position: absolute;
  right: -30px;
  height: 120%;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  @media (max-width: 1024px) {
    right: -5px;
  }

  @media (max-width: 576px) {
    left: 50%;
    right: initial;
    transform: translateX(-50%);
  }

  img {
    display: flex;
  }

  &__item {
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.15);
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &--first {
      animation-name: letter-first;
      -moz-animation-name: letter-first;
      -webkit-animation-name: letter-first;
      animation-duration: 10s;
      -moz-animation-duration: 10s;
      -webkit-animation-duration: 10s;
    }

    &--second {
      animation-name: letter-second;
      -moz-animation-name: letter-second;
      -webkit-animation-name: letter-second;
      animation-duration: 10s;
      -moz-animation-duration: 10s;
      -webkit-animation-duration: 10s;
    }

    &--third {
      animation-name: letter-third;
      -moz-animation-name: letter-third;
      -webkit-animation-name: letter-third;
      animation-duration: 10s;
      -moz-animation-duration: 10s;
      -webkit-animation-duration: 10s;
    }

    @media (max-width: 1440px) {
      width: 308px;
    }

    @media (max-width: 1024px) {
      width: 228px;
    }
  }
}

@-moz-keyframes letter-first {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@-webkit-keyframes letter-first {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes letter-first {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@-moz-keyframes letter-second {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@-webkit-keyframes letter-second {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  35% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes letter-second {
  0% {
    opacity: 0;
    transform: translateY(500%);
  }

  35% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@-moz-keyframes letter-third {
  0% {
    opacity: 0;
    transform: translateY(150%);
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  90% {
    opacity: 0.5;
  }

  95% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@-webkit-keyframes letter-third {
  0% {
    opacity: 0;
    transform: translateY(150%);
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  90% {
    opacity: 0.5;
  }

  95% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes letter-third {
  0% {
    opacity: 0;
    transform: translateY(150%);
  }

  60% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  90% {
    opacity: 0.5;
  }

  95% {
    opacity: 0.25;
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
