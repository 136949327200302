.icon {
    width: 66px;
    height: 66px;
    background-color: var(--blue-6);
    box-shadow: 0px 0px 32px rgba(0, 71, 254, 0.3);
    border-radius: 12px;
    padding: 14px;

    svg {
      // fill: #fff;
      stroke: #fff;
    }
}
