.contacts {
    padding: 100px clamp(20px, 5.215vw, 30px) 260px;
    overflow: hidden;

    &.line::before {
        top: -10%;
    }

    @media (max-width: 1240px) {
        padding-top: 80px;
        padding-bottom: 240px;
    }

    @media (max-width: 576px) {
        padding: 80px 30px 200px;
    }

    &__download {
        padding: 230px 40px;

        @media (max-width: 1440px) {
            padding: 140px 40px 130px;
        }

        @media (max-width: 1240px) {
          padding: 80px 40px 130px;
        }

        @media (max-width: 786px) {
            padding: 90px 20px;
        }
    }

    &__download-wrapp {
        display: flex;
        justify-content: center;
        grid-gap: clamp(30px, 6.25vw, 120px);

        @media (max-width: 1240px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__download-image {
      width: clamp(695px, 38.542vw, 740px);
      height: clamp(630px, 38.542vw, 740px);

      @media (max-width: 1240px) {
        width: clamp(320px, 51.613vw, 640px);
        height: clamp(290px, 46.774vw, 580px);
      }
    }

    &__download-details {
        display: flex;
        flex-direction: column;

        @media (max-width: 1240px) {
            max-width: 1024px;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__download-fastlink {
        margin-bottom: 60px;

        @media (max-width: 1240px) {
            order: 3;
            margin-bottom: 0;
        }

        @media (max-width: 768px) {
            order: 2;
            margin-bottom: 40px;
        }
    }

    &__download-googlplay {
        margin-bottom: 34px;

        @media (max-width: 1240px) {
            order: 2;
            margin-bottom: 0;
        }

        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
    }

    &__download-appstore {
        @media (max-width: 1240px) {
            order: 4;
        }
    }

    &__download-info {
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        grid-gap: 30px;

        @media (max-width: 1240px) {
            flex-grow: 1;
            width: 100%;
            margin: 0 auto 60px;
            text-align: center;
        }
    }

    &__info-title {
        color: var(--system-6);
        max-width: 710px;

        @media (max-width: 1240px) {
            max-width: 660px;
            margin: 0 auto;
        }
    }

    &__info-descr {
        color: var(--system-4);
    }

    &__info {
        display: flex;
        justify-content: space-between;
        grid-gap: clamp(102px, 10.313vw, 198px);

        @media (max-width: 1240px) {
            flex-direction: column;
            grid-gap: 140px;
        }
    }

    &__info-details {
        display: flex;
        flex-direction: column;
        grid-gap: 80px;

        @media (max-width: 1240px) {
            max-width: 745px;
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        grid-gap: 20px;
    }

    &__socials-link {
        width: 50px;
        height: 50px;
        background-color: var(--blue-2);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;

        svg {
            fill: var(--blue-6);
        }

        &:hover {
            filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));
            background-color: var(--blue-6);

            svg {
                fill: var(--system-6);
            }
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        grid-gap: clamp(40px, 7.813vw, 60px);
    }

    &__details-item {
        display: flex;
        flex-direction: column;
        grid-gap: clamp(12px, 1.823vw, 14px);
    }

    &__details-title {
        color: var(--system-3);
    }

    &__adress {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;

        @media (max-width: 576px) {
            grid-gap: 30px;
        }
    }

    &__adress-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 10px;
        }
    }

    &__adress-worktime {
        display: flex;
        grid-gap: 44px;
    }

    &__worktime {
        @media (max-width: 576px) {
            font-size: 14px;
            line-height: 117%;
        }
    }
}
