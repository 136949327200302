.feedback {
  // background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);
  padding: 130px clamp(20px, 5.215vw, 30px);

  &__info {
    display: grid;
    align-items: flex-start;
    grid-template-columns: clamp(550px, 30.521vw, 586px) 1fr;
    grid-gap: clamp(30px, 3.125vw, 60px);
    width: 100%;


    @media (max-width: 1024px) {
      grid-template-columns: 100%;
    }
  }

  &__all {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    padding: 40px 40px 30px;
    background-color: var(--blue-6);
    border-radius: 20px;

    @media (max-width: 1024px) {
      padding: 30px;
    }

    @media (max-width: 1024px) {
      padding: 40px 45px 30px;
    }

    @media (max-width: 575px) {
      padding: 20px;
    }
  }

  &__total {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;

    @media (max-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__overall-rating {
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  &__overall-numbers {
    color: var(--system-6);
  }

  &__overall-text {
    color: var(--blue-2);

    @media (max-width: 1440px) {
      margin-top: 10px;
    }

  }

  &__btns {
    flex-direction: column;
    grid-gap: 10px;

    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__btn-add {
    color: var(--system-6);

    svg {
      stroke: var(--system-6);
    }

    @media (max-width: 1024px) {
      order: 1;
    }
  }

  &__btn-all {
    width: 100%;
    background-color: var(--system-6);
    color: var(--blue-6);
  }

  &__total-reviews {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    @media (max-width: 1024px) {
      grid-gap: 24px;
    }

    @media (max-width: 575px) {
      display: none;
    }
  }

  &__reviews {
    max-width: 506px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px 12px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 71, 254, 0) 0%, rgba(0, 71, 254, 0.7) 100%);
      pointer-events: none;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &__reviews-item {
    padding: 8px;
    background: #FFFFFF;
    border-radius: 5px;
    color: var(--blue-9);
  }

  &__reviews-number {
    color: var(--blue-9);
  }

  &__all-comments {
    position: relative;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   height: 72px;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    //   border-top-left-radius: 14px;
    //   border-top-right-radius: 14px;
    //   pointer-events: none;
    // }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   height: 72px;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    //   transform: rotate(-180deg);
    //   border-bottom-left-radius: 14px;
    //   border-bottom-right-radius: 14px;
    //   pointer-events: none;
    // }
  }

  &__comments {
    display: flex;
    flex-direction: column;
    grid-gap: 26px;
    width: 100%;
    height: 100%;
    max-height: var(--comments-max-height);
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media (max-width: 1024px) {
      max-height: 703px;
    }
  }

  &__comments-item {
    display: flex;
    flex-direction: column;
    grid-gap: 26px;
    padding: 20px;
    background-color: var(--system-5);
    border-radius: 14px;
  }

  &__comments-head {
    display: flex;
    flex-direction: column;
    grid-gap: 13px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__star-rate {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: var(--stars-rate);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      left: -30px;
    }
  }
}
