.lang {
	position: relative;
	width: 44px;
    height: 44px;
    border: 1px solid var(--blue-6);
    border-radius: 8px;

    &__header {
        padding: 13px 10px;
        cursor: pointer;
    }

    &__current {
        display: flex;
        border-radius: 2px;
    }

    &__body {
        display: none;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 44px;
        height: 108px;
        border: 1px solid var(--blue-6);
        border-radius: 8px;
        padding: 13px 10px;
        background-color: var(--system-6);
        z-index: 5;


    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        z-index: 0;

        & + & {
            margin-top: 14px;
        }

        svg {
            pointer-events: none;
        }

        &:hover {
            &::before {
                background-color: var(--blue-6);
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: -11px;
            right: -11px;
            top: -8px;
            bottom: -8px;
            background-color: transparent;
            z-index: -1;
            transition: all 0.4s;
        }
    }
}


