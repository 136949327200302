.basket {
    &__wrapp {
        padding: 0 clamp(20px, 5.215vw, 30px);
        position: relative;
        display: grid;
        align-items: flex-start;
        grid-template-columns: 1fr clamp(380px, 24.479vw, 470px);
        grid-gap: Min(4.167vw, 80px);

        @media (max-width: 1240px) {
          display: flex;
          flex-direction: column;
          // grid-gap: 80px;
        }

        @media (max-width: 576px) {
          padding: 0 10px;
        }
    }

    &__inner {
        padding-top: 30px;
        max-width: 1290px;
        width: 100%;
    }

    &__steps {
        position: relative;
        max-width: 433px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 8px;
        z-index: 3;

        span {
            color: var(--system-2);
        }

        svg {
            fill: var(--system-2);
            stroke: var(--system-3);
        }

        &--active {
            span {
                color: var(--blue-6);
            }

            svg {
                fill: var(--blue-6);
                stroke: var(--blue-2);
            }
        }
    }

    &__progressbar {
        position: absolute;
        z-index: 1;
        top: 9px;
        left: 25px;
        right: 35px;
        height: 1px;
        background: var(--system-3);

        &--active {
            background: var(--blue-6);
        }
    }

    &__box {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        grid-gap: 80px;
    }

    &__order {
        background-color: var(--system-5);
        border-radius: 8px;
    }

    &__order-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--blue-6);
        padding: 10px 40px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media (max-width: 576px) {
          padding: 8px 16px;
        }
    }

    &__order-total {
        display: flex;
        grid-gap: 12px;
        color: var(--system-6);
    }

    &__order-cancel {
        height: 40px;
        border: 1px solid var(--system-6);
        border-radius: 6px;
        padding: 0 14px;
        color: var(--system-6);
        transition: all 0.5s ease-in-out;

        svg {
            stroke: var(--system-6);
            margin-right: 10px;
        }

        &:hover {
            border: 1px solid transparent;
            background-color: var(--error-2);
        }

        @media (max-width: 576px) {
          padding: 10px;

          span {
            display: none;
          }

          svg {
            margin-right: 0;
          }
        }
    }

    &__order-details {
        display: flex;
        flex-direction: column;
        grid-gap: 26px;
        padding: 28px 40px 40px;

        @media (max-width: 576px) {
          padding: 26px 16px;
        }
    }

    &__details-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 920px;
        width: 100%;

        .basket__calculation-items {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            grid-gap: initial;

            @media (max-width: 576px) {
              grid-gap: 20px 22px;
            }
        }


    }

    &__order-list {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        grid-gap: 1px;
    }

    &__order-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        border-left: 1px solid var(--blue-6);
        background-color: var(--system-6);
        transition: all 0.5s ease-in;
        grid-gap: 20px;

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .basket__order-choose {
                border-top-right-radius: 4px;
            }
        }

        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &:hover {
            .basket__order-file {
                color: var(--blue-6);
            }

            .basket__order-choose {
                background-color: var(--blue-6);
            }

            .basket__settings--edit {
                svg {
                    stroke: var(--blue-6);
                }
            }

            .basket__settings--delete {
                svg {
                    stroke: var(--error-2);
                }
            }
        }

        @media (max-width: 1240px) {
          flex-wrap: wrap;
        }

        @media (max-width: 1024px) {
          flex-wrap: nowrap;
        }

        @media (max-width: 880px) {
          flex-wrap: wrap;
        }

        @media (max-width: 576px) {
          flex-direction: column;
          padding: 20px 16px;
        }
    }

    &__order-file {
        transition: all 0.5s ease-in;
    }

    &__order-choose {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        background-color: var(--system-3);
        color: var(--system-6);
        border-radius: 0px 0px 0px 4px;
        transition: all 0.5s ease-in;
    }

    &__order-info {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
    }

    &__order-choosed {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 6px;
        max-width: 505px;
    }

    &__choosed-list {
        display: flex;
        align-items: center;
        grid-gap: 6px;
        flex-shrink: 0;
        padding: 4px;
        background-color: var(--system-5);
        border-radius: 4px;

        svg {
            fill: var(--system-2);
        }
    }

    &__order-price {
        display: flex;
        align-items: center;
        grid-gap: clamp(30px, 3.125vw, 60px);
    }

    &__order-count {
        display: flex;
        align-items: center;
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
    }

    &__count-price {
      min-width: 100px;
        color: var(--blue-6);
    }

    &__count-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        background-color: var(--blue-1);
        border-radius: 40px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        svg {
            transition: all 0.4s ease-in-out;
            fill: var(--blue-7);
        }

        &:hover {
            background-color: var(--blue-6);

            svg {
                fill: var(--system-6);
            }
        }
    }

    &__order-settings {
        display: flex;
        align-items: center;
        grid-gap: 30px;

        @media (max-width: 576px) {
          width: 100%;
          justify-content: space-evenly;
        }
    }

    &__settings {
        transition: all 0.5s ease-in;
        cursor: pointer;

        svg {
            stroke: var(--system-1);
            cursor: pointer;
        }
    }

    &__empty {
        padding: 170px 0;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        grid-gap: 50px;
    }

    &__empty-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 30px;
    }

    &__empty-btn {
        color: var(--system-6);
        background-color: var(--blue-6);
        padding: 16.5px 77px;
    }

    &__calculation {
        position: sticky;
        top: 20px;
        max-width: 470px;
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
        padding: 30px 40px;
        background-color: var(--system-5);
        border-radius: 8px;
        flex-shrink: 0;

        @media (max-width: 1240px) {
          position: relative;
          max-width: 100%;
          top: 0;
          margin-top: 40px;
        }

        @media (max-width: 576px) {
          padding: 20px 16px;
        }
    }

    &__calculation-head {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
    }

    &__calculation-items {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;

        @media (max-width: 1240px) {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
        }

        @media (max-width: 576px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px 22px;
        }
    }

    &__calculation-item {
        display: flex;
        flex-direction: column;
        grid-gap: 6px;
    }

    &__calculation-total {
        border-top: 1px solid var(--system-3);
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
    }

    &__calculation-btns {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }

    &__btn {
        background-color: var(--blue-6);
        color: var(--system-6);
        transition: all 0.5s ease-in;

        &:hover {
            background-image: linear-gradient(#3F7AFE 0%, #0047FE 100%);
            filter: drop-shadow(0px 0px 32px rgba(0, 71, 254, 0.3));
        }

        &:active {
            background-color: var(--blue-8);
        }

        &--empty {
            background-color: var(--system-3);
        }
    }

    &__btn-confirm {
        border: 1px solid #0047FE;
        background-color: #fff;
        border-radius: 6px;
    }

    &__btn-label {
      padding: 14px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.4s;

        &:checked ~ .basket__btn-checkbox::after{
          opacity: 1;
          background-color: #0047FE;
        }

        &:checked ~ .basket__btn-checkbox::before {
          background-color: #EDF4FF;
          border: 1px solid #0047FE;
        }
      }

      a {
        word-wrap: break-word;
        color: #0047FE;
        text-decoration: underline;
      }
    }

    &__btn-checkbox {
      position: relative;
      padding-left: 32px;
      transition: all 0.4s;
      text-align: left;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        width: 20px;
        height: 20px;
        border: 1px solid #181818;
        border-radius: 2px;
        transition: all 0.4s;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        background-color: #0047FE;
        opacity: 0;
        transition: all 0.4s;
      }

      &:hover {
        color: #0047FE;

        &::before {
          border: 1px solid #0047FE;
        }
      }
    }



    &__copy {
        padding: 80px clamp(20px, 5.215vw, 30px) 24px;
    }
}
